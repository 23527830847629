<template>
  <div class="live-container">
    <header-nav v-if="isShow"></header-nav>
    <div class="main">
      <div class="main-header"></div>
      <!-- 产品 -->
      <div class="productList" v-show="totalNum > 0">
        <!-- <Row type="flex" justify="space-between" class="code-row-bg">
          <Col
            span="8"
            class="productContainer"
            v-for="item in productList"
            :key="item.id"
          >
            <div @click="goProductDetail(item.id)">
              <img
                :src="item.productUrl"
                alt=""
                width="180px"
                height="180px"
                style="margin-top: 30px"
              />
              <div class="productName">{{ item.productName }}</div>
              <div class="productDescribe">
                {{ item.productDescribe }}
              </div>
              <div class="productDetail">
                Read MORE
                <Icon type="md-arrow-round-forward" style="margin-top: -2px" />
              </div>
            </div>
          </Col>
        </Row> -->

        <div
          class="productContainer"
          v-for="item in productList"
          :key="item.id"
        >
          <div @click="goProductDetail(item.id)">
            <img
              :src="item.productUrl"
              alt=""
              width="180px"
              height="180px"
              style="margin-top: 30px;object-fit: contain;"
            />
            <!-- <div class="productName" v-auto-shrink-text :style="{ fontSize: 'inherit'}">{{ item.productName }}</div> -->
            <div class="productName">{{ item.productName }}</div>
            <div class="productDescribe">
              <div style="word-break: break-all;overflow: hidden;display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 4;">
                {{ item.productDescribe }}
              </div>
            </div>
            <div class="productDetail">
              Read MORE
              <Icon type="md-arrow-round-forward" style="margin-top: -2px" />
            </div>
          </div>
        </div>
      </div>

      <div class="dataPage" v-show="totalNum > 0">
        <Page
          :total="totalNum"
          show-elevator
          show-total
          :page-size="pageSize"
          :current="currentPage"
          @on-change="changePage"
          @on-page-size-change="changePageSize"
        />
      </div>

      <div class="zero" v-show="totalNum <= 0">
        <img src="../assets/images/zero.png" alt />
        <p>本栏目正在维护</p>
      </div>
    </div>
    <footer-nav></footer-nav>

    <!-- 定位栏 -->
    <location-column></location-column>
  </div>
</template>

<script>
import headerNav from "@/components/header-nav";
import footerNav from "@/components/footer-nav";
import { getProductList, getProductDetail } from "@/api/newWebsite";
export default {
  name: "product-center",
  components: { headerNav, footerNav },
  metaInfo: {
    title:
      "新巢天诚-产品中心 智慧通行|人脸识别终端|人行通道闸机|CAT.1智能门锁|LORA智能门锁|UNB联网锁|NB人脸智能门锁|WiFi无线物联网锁|人才公寓管理系统|智慧公寓管理系统",
      meta: [
      {
        name: "keywords",
        content:
          "天诚NB-IoT智能门锁，CAT.1物联网锁，Wi-Fi智能锁，4G智能门锁，校园智能门锁，保障性住房管理平台，公租房管理系统，人才公寓管理系统，网约房（出租屋管理系统），智慧校园后勤管理系统，智慧园区企业后勤管理系统。",
      },
      {
        name: "description",
        content:
          "江苏新巢天诚智能技术有限公司（简称：天诚）专注于物联网多形态智能硬件的产品研发与制造，为不同垂直领域的客户提供整体解决方案服务，深度赋能公租房.人才公寓、智慧公寓、人才公寓、保障性住房、智慧校园、企业园区宿舍、智慧后勤，网约房（出租屋）等行业应用场景。天诚自主研发的多模态计算机视觉与生物识别物联网锁，采用NB-IoT、CAT.1、Wi-Fi、4G,5G等无线通讯技术。打造整体的智能硬件解决方案。",
      },
    ],
  },
  data() {
    return {
      isShow:true,
      id:0,
      totalNum: 0,
      pageSize: 15,
      currentPage: 1,
      productList: [],
      lastScroll:"",
      scrollPosition:"",
      flag:true,
    };
  },
  directives: {
    autoShrinkText: {
      inserted(el) {
        autoShrink(el)
        window.addEventListener('resize', () => autoShrink(el))
      },
      componentUpdated(el) {
        autoShrink(el)
      },
      unbind(el) {
        window.removeEventListener('resize', () => autoShrink(el))
      }
    }
  },
  methods: {
    changePage(index) {
      this.currentPage = index;
      this.getProductList();
      let width = window.innerWidth;
      if(width && width <= 768){
        window.scrollTo({
          top: 180,
          behavior: 'auto',
        });
      }else{
        window.scrollTo({
          top: 400,
          behavior: 'auto',
        });
      }
    },
    changePageSize(index) {
      this.pageSize = index;
      this.getProductList();
    },

    goProductDetail(value) {
      // console.log("产品id", value);

      this.$router.push({
        name: "device-detail",
        query: { id: value },
      });
    },

    //获取产品中心列表数据
    getProductList() {
      getProductList({
        categoryId: this.id,
        pageNum: this.currentPage,
        pageSize: this.pageSize,
      })
        .then((res) => {
          if (res.code == 0) {
            this.productList = res.data.list;
            this.totalNum = res.data.pageTotal;
          } else {
            this.$Message.error(res.message);
          }
        })
        .catch((err) => {
          this.$Message.error("获取数据失败,请稍后再试");
        });
    },

    handleScroll() {
      if (!this.lastScroll){
        this.lastScroll = 0;
      }
      const now = Date.now();
      // if (now - this.lastScroll > 100) { //现关闭节流，否则定位不是最后一帧的实际位置
        // 获取窗口滚动位置（兼容不同浏览器）
        const currentScrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
        this.scrollPosition = currentScrollTop;
        this.lastScroll = now;
      // }
    }
  },
  created() {
    // let id = this.$route.query.id;
    // if(id){
    //   this.id = id;
    //   // console.log("当前分类id",id)
    // }
    // if(this.flag){
    //   this.getProductList();  //获取当前产品列表
    // }
  },
  mounted() {
    // document.title = this.$store.state.searchTitle;
  },

  beforeRouteUpdate(to, from, next) {
    next();

    let id = this.$route.query.id;
    this.id = id;
    sessionStorage.setItem("productId",id)
    window.scrollTo({
      top: 0,
      behavior: 'auto',
    });
    this.currentPage = 1;
    this.getProductList();  //获取当前产品列表
  },

  activated(){ //创建前
    this.isShow = false;
    this.$nextTick(() => {
      this.isShow = true;
    });

    let id = this.$route.query.id;
    this.id = id;
    if(id){
      let productId = sessionStorage.getItem("productId")
      let scrollY = sessionStorage.getItem("scrollY")
      if(productId && productId == id && this.productList.length > 0){
        if(scrollY){
          window.scrollTo({
            top: scrollY,
            behavior: 'auto',
          });
        }else{
          window.scrollTo({
            top: 0,
            behavior: 'auto',
          });
        }
      }else{
        sessionStorage.setItem("productId",id)
        window.scrollTo({
          top: 0,
          behavior: 'auto',
        });
        this.getProductList();  //获取当前产品列表
      }
      
      window.addEventListener('scroll', this.handleScroll)
    }else{
      this.$router.push({
        name: "Home",
      });
      sessionStorage.setItem("checkedIndex", 1);
    }
  }, 
  deactivated(){//销毁前
    window.removeEventListener('scroll', this.handleScroll)
    sessionStorage.setItem("scrollY", this.scrollPosition);
  },
};

// 公共调整函数
function autoShrink(el) {
  const container = el.parentElement
  if (!container) return

  let fontSize = parseInt(getComputedStyle(el).fontSize)
  const originalSize = fontSize // 保留初始字号
  
  // 重置到原始大小重新计算
  el.style.fontSize = originalSize + 'px'

  // 循环缩小字体
  while (el.scrollWidth > container.clientWidth && fontSize > 8) {
    fontSize--
    el.style.fontSize = fontSize + 'px'
  }
}
</script>

<style scoped>
.live-container {
  /* min-width: 1366px; */
}
.main {
  width: 100%;
  padding-top: 80px;
  padding-bottom: 40px;
}
.main-header {
  width: 100%;
  height: 386px;
  text-align: center;
  background-image: url("../assets/images/new/product.jpeg");
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
.productList {
  position: relative;
  top: 40px;
  min-height: 800px;
  max-width: 1200px;
  margin: auto;
  display: flex;
  /* justify-content: space-between; */
  flex-wrap: wrap;
  justify-items: center;
}
.productContainer {
  width: 30%;
  height: 470px;
  /* padding-left: 50px;
  padding-right: 50px; */
  margin-bottom: 20px;
  box-shadow: 1px 1px 6.44px 2.56px rgb(232 235 234 / 39%);
  border-radius: 8px;
  cursor: pointer;
  margin-right: 36px;
  font-size: 24px;
  position: relative;
}
.productContainer:hover img {
  box-shadow: 0px 0px 5px #888888;
  /* -moz-transform: scale(1.1, 1.1);
  -webkit-transform: scale(1.1, 1.1);
  -o-transform: scale(1.1, 1.1); */
  /* -webkit-transform: rotateX(180deg); */
  -moz-transform: scale(1.15, 1.15);
  -webkit-transform: scale(1.15, 1.15);
  -o-transform: scale(1.15, 1.15);
  transition: all 1s;
  border: none;
  box-shadow: none;
}
.productName {
  color: #272b2e;
  font-size: 24px;
  letter-spacing: 0px;
  line-height: 36px;
  margin-bottom: 10px;
  font-weight: 500;
  padding:0 23px;
  /* white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; */
}
.productDescribe {
  color: #878a95;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0px;
  font-weight: 400;
  margin: 30px auto;
  height: 95px;
  padding:0 50px;
}
.productDetail {
  font-size: 14px;
  letter-spacing: 0px;
  color: #bc9265;
  text-transform: uppercase;
  display: inline-block;
  font-weight: 500;
  line-height: 22px;
  cursor: pointer;
  position: absolute;
  width: 104px;
  bottom: 22px;
  left: calc(50% - 52px);
}

.dataPage {
  margin-top: 80px;
  margin-bottom: 30px;
}
.dataPage >>> .ivu-page-item-active {
  border-color: #ea5413;
}
.dataPage >>> .ivu-page-item-active a {
  color: #ea5413;
}
.dataPage >>> .ivu-page-item:hover {
  border-color: #ea5413;
  color: #ea5413;
}
.dataPage >>> .ivu-page-item a:hover {
  color: #ea5413 !important;
}
.zero{
  max-width: 1200px;
  margin: 100px auto;
}
.zero img{
 width: 350px;
}
.zero p{
  font-size: 24px;
  margin-top: 10px;
}
@media screen and (max-width: 768px) {
  .main {
    padding-top: 50px;
  }
  .main-header {
    height: 166px;
  }
  .productList {
    display: block;
  }
  .productContainer {
    width: 100%;
    margin-right: 0px;
  }

  .productList {
    /* max-height: 1200px; */
  }

  .zero{
    max-width: 1200px;
    margin: 50px auto;
  }
  .zero img{
    width: 150px;
  }
  .zero p{
    font-size: 18px;
    margin-top: 10px;
  }
}
</style>